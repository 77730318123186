import { buildApiClientFromNextJsContext } from '@/api/apiClient';
import { buildUrlFromSearch, Search } from '@/api/search';
import { ApiClientOptions } from '@/types/apiclient';
import { Publication as PublicationConstants } from '@/types/constants';
import { Publication, PublicationType } from '@/types/core/Publication';
import { User } from '@/types/core/User';
import { SearchResult } from '@/types/search/searchresult';

export async function searchPublications(
  search: Search,
  options?: ApiClientOptions
): Promise<SearchResult<Publication>> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const response = await apiClient(
    buildUrlFromSearch('/api/publications', search)
  );
  return response.json();
}

export async function fetchFeaturedPublications(
  countryId: string | null,
  options?: ApiClientOptions
): Promise<SearchResult<Publication>> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  let url =
    `/api/publications` +
    '?fields[publication]=id,slug,title,imageAlt' +
    '&includes=image' +
    '&fields[file]=filename' +
    '&fields[user]=displayName,isExpert' +
    '&filters[publishedStatus]=state.published' +
    '&filters[featured]=1' +
    '&order=publishedAt';
  if (countryId) {
    url += `&filters[worldOrCountry]=${countryId}`;
  }
  const response = await apiClient(url);
  return response.json();
}

export async function fetchPinnedPublications(
  options?: ApiClientOptions
): Promise<SearchResult<Publication>> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url =
    `/api/publications` +
    '?fields[publication]=id,slug,title,abstract,text,imageAlt,categories,criteria,countComments,countRecommendations,countSelections,isSelected,isReported,publishedAt,pinned' +
    '&includes=country,author.country,author.avatar,image,medias.file' +
    '&fields[resource]=subtitle,labelized,categories,requiredCECRLevels' +
    '&fields[news]=newsTypes' +
    '&fields[media]=title,description,mediaType' +
    '&fields[videoMedia]=url,imagePreview' +
    '&fields[soundMedia]=url' +
    '&fields[linkMedia]=url' +
    '&fields[file]=filename' +
    '&fields[user]=displayName,roles,isExpert' +
    '&fields[country]=name' +
    '&filters[publishedStatus]=state.published' +
    '&filters[pinned]=1' +
    '&filters[types]=pedagogicalResource,news' +
    '&order=publishedAt';
  const response = await apiClient(url);
  return response.json();
}

export async function fetchPublicationWithComments(
  publicationId: string,
  options?: ApiClientOptions
): Promise<Publication> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url =
    `/api/publications/${publicationId}` +
    '?includes=country,author.country,author.avatar,comments.author.avatar,comments.file,medias.file,image,documents,documents.media,documents.media.file,group' +
    '&fields[publication]=id,title,text,abstract,subtitle,imageAlt,categories,criteria,countComments,countRecommendations,countSelections,isSelected,isReported,publishedAt,pinned' +
    '&fields[resource]=subtitle,labelized,categories,requiredCECRLevels' +
    '&fields[discussion]=open' +
    '&fields[news]=newsTypes' +
    '&fields[media]=title,description,mediaType' +
    '&fields[videoMedia]=url,imagePreview' +
    '&fields[soundMedia]=url' +
    '&fields[file]=filename' +
    '&fields[comment]=content,countSelections,createdAt,isSelected,isReported' +
    '&fields[user]=displayName,roles' +
    '&fields[country]=name' +
    '&fields[group]=name' +
    '&filters[publishedStatus]=state.published' +
    '&filters[pinned]=1';
  const response = await apiClient(url);
  return response.json();
}

export async function fetchRecentGroupDiscussions(
  userId: string,
  options?: ApiClientOptions
): Promise<SearchResult<Publication>> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url =
    '/api/publications' +
    '?fields[publication]=title,slug,countComments' +
    '&fields[group]=name,slug' +
    '&items=5' +
    '&includes=group' +
    '&order=-lastCommented,-publishedAt' +
    '&filters[type]=discussion' +
    '&filters[groupMember]=' +
    userId;
  const response = await apiClient(url);
  return response.json();
}

export async function fetchPublicationDetail(
  publicationId: string,
  user?: User,
  options?: ApiClientOptions
): Promise<Publication | null> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url =
    `/api/publications/${publicationId}` +
    '?fields[publication]=featured,title,text,abstract,slug,updatedAt,publishedAt,mediaTypes,state,countSelections,countComments,countSelections,countRecommendations,countComments,imageAlt,isReported' +
    (user ? `,isSelected:${user.id}` : '') +
    '&includes=medias,medias.file,contact,location,partners,partners.image,image,author,author.country,author.avatar,links.country,links.author,links.group,linkedBy.country,' +
    'comments.author.avatar,comments.author.country,comments.respondTo,' +
    'comments.responses.author.avatar,comments.responses.author.country,' +
    'comments.responses.responses.author.avatar,comments.responses.responses.author.country,country,' +
    'documents,documents.media,documents.media.file,' +
    'group.country,group.admin.country,group.admin.avatar,discussion' +
    '&fields[pedagogicalResource]=learnerAges,activityTypes,sequencings,translatedCategories,translatedCriteria' +
    '&fields[media]=description,rank,title,mediaType' +
    '&fields[file]=originalFilename,filename' +
    '&fields[fileMedia]=filename,originalFilename' +
    '&fields[linkMedia]=url' +
    '&fields[videoMedia]=url,imagePreview' +
    '&fields[soundMedia]=url' +
    '&fields[user]=displayName,slug,roles,jobs,disciplines,isExpert,colleagueState:' +
    (user ? user.id : '') +
    (user ? ',isColleague:' + user.id : '') +
    '&fields[resource]=themes,disciplines,labelized,categories,criteria,subtitle,labelizationStatus,requiredCECRLevels' +
    '&fields[country]=name,primaryColor,code' +
    '&fields[comment]=subject,content,countResponses,state,isSelected,createdAt' +
    '&fields[group]=name,slug,goal,target,subjects,trainingGoals,disciplines,countMembers,isMember,private' +
    '&fields[news]=newsTypes';

  const response = await apiClient(url);
  if (response.status === 404) {
    return null;
  }
  return response.json();
}

export async function fetchPopularPublications(
  perPage: number,
  page: number,
  filters?: Record<string, string>,
  options?: ApiClientOptions
): Promise<SearchResult<Publication>> {
  let searchFilters: Record<string, string> = {
    publishedStatus: PublicationConstants.STATE_PUBLISHED,
    notPinned: '1',
    types: 'pedagogicalResource,news',
  };
  if (filters) {
    searchFilters = {
      ...searchFilters,
      ...filters,
    };
  }

  const publications = await searchPublications(
    {
      fields: {
        publication:
          'id,slug,title,abstract,text,imageAlt,categories,criteria,countComments,countRecommendations,countSelections,isSelected,isReported,publishedAt,pinned',
        resource: 'subtitle,labelized,categories,requiredCECRLevels',
        news: 'newsTypes',
        media: 'title,description,mediaType',
        videoMedia: 'url,imagePreview',
        soundMedia: 'url',
        file: 'filename',
        linkMedia: 'url',
        user: 'displayName,roles,isExpert',
        country: 'name',
      },
      relationIncludes: [
        'country',
        'author.country',
        'author.avatar',
        'medias.file',
        'image',
      ],
      filters: searchFilters,
      orders: ['-popularity'],
      pagination: { itemsPerPage: perPage, page },
    },
    options
  );

  return publications;
}

export async function fetchRecentPublications(
  perPage: number,
  page: number,
  filters?: Record<string, string>,
  options?: ApiClientOptions
): Promise<SearchResult<Publication>> {
  let searchFilters: Record<string, string> = {
    publishedStatus: PublicationConstants.STATE_PUBLISHED,
    notPinned: '1',
  };
  if (filters) {
    searchFilters = {
      ...searchFilters,
      ...filters,
    };
  }
  return await searchPublications(
    {
      fields: {
        publication:
          'id,slug,title,abstract,text,imageAlt,categories,criteria,countComments,countRecommendations,countSelections,isSelected,isReported,publishedAt,pinned',
        resource: 'subtitle,labelized,categories,requiredCECRLevels',
        news: 'newsTypes',
        media: 'title,description,mediaType',
        videoMedia: 'url,imagePreview',
        linkMedia: 'url',
        soundMedia: 'url',
        file: 'filename',
        comment: 'content,countSelections,createdAt,isSelected,isReported',
        user: 'displayName,roles,isExpert',
        country: 'name',
      },
      relationIncludes: [
        'country',
        'author.country',
        'author.avatar',
        'comments.author.avatar',
        'comments.file',
        'medias.file',
        'image',
      ],
      filters: searchFilters,
      orders: ['-publishedAt'],
      pagination: { itemsPerPage: perPage, page },
    },
    options
  );
}

export async function fetchUserPublications(
  userId: string,
  itemsPerPage: number,
  page = 1,
  filters?: Record<string, string>,
  options?: ApiClientOptions
) {
  let searchFilters: Record<string, string> = {
    publishedStatus: 'all',
    author: userId,
    types: 'pedagogicalResource,news',
  };

  if (filters) {
    searchFilters = {
      ...searchFilters,
      ...filters,
    };
  }
  return await searchPublications(
    {
      fields: {
        publication:
          'id,slug,title,abstract,text,imageAlt,categories,criteria,countComments,countRecommendations,countSelections,isSelected,isReported,publishedAt,pinned',
        resource: 'subtitle,labelized,categories',
        news: 'newsTypes',
        media: 'title,description,mediaType',
        videoMedia: 'url,imagePreview',
        soundMedia: 'url',
        file: 'filename',
        comment: 'content,countSelections,createdAt,isSelected,isReported',
        user: 'displayName,roles',
        country: 'name',
      },
      relationIncludes: [
        'country',
        'author.country',
        'author.avatar',
        'comments.author.avatar',
        'comments.file',
        'medias.file',
        'image',
      ],
      filters: searchFilters,
      orders: ['-createdAt'],
      pagination: { itemsPerPage, page, countTotalResults: true },
    },
    options
  );
}

export async function fetchUserDiscussions(
  user: User,
  itemsPerPage: number,
  page = 1,
  orders?: string[],
  options?: ApiClientOptions
) {
  return await searchPublications(
    {
      fields: {
        publication:
          'slug,title,abstract,text,subtitle,imageAlt,categories,criteria,countComments,countRecommendations,countSelections,isSelected,isReported,publishedAt,pinned',
        discussion: 'open',
        resource: 'labelized',
        news: 'newsTypes',
        media: 'title,description,mediaType',
        videoMedia: 'url,imagePreview',
        soundMedia: 'url',
        linkMedia: 'url',
        file: 'filename',
        comment: 'content,countSelections,createdAt,isSelected,isReported',
        user: 'displayName,roles,isExpert',
        country: 'name',
        group: 'name',
      },
      relationIncludes: [
        'country',
        'author.country',
        'author.avatar',
        'comments.author.avatar',
        'comments.file',
        'medias.file',
        'image',
        'group',
      ],
      filters: {
        type: 'discussion',
        publishedStatus: 'state.published',
        commentedBy: user.id,
      },
      orders: orders,
      pagination: { itemsPerPage, page, countTotalResults: true },
    },
    options
  );
}

export async function pinPublication(
  publicationId: string,
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  return apiClient(`/api/publications/${publicationId}/pin`, {
    method: 'POST',
  });
}

export async function unpinPublication(
  publicationId: string,
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  return apiClient(`/api/publications/${publicationId}/unpin`, {
    method: 'POST',
  });
}

export async function deletePublication(
  publicationId: string,
  publicationType: PublicationType,
  options?: ApiClientOptions
): Promise<Response> {
  const type = {
    pedagogicalResource: 'resources',
    news: 'news',
    discussion: 'discussions',
  };

  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  return apiClient(`/api/${type[publicationType]}/${publicationId}`, {
    method: 'DELETE',
  });
}

export async function selectPublication(
  publicationId: string,
  action: 'select' | 'unselect',
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  return apiClient(`/api/publications/${publicationId}/${action}`, {
    method: 'POST',
  });
}
