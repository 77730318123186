export const Group = {
  STATE_OPEN: 'OPEN',
  STATE_READ_ONLY: 'READ_ONLY',
  STATE_CLOSE: 'CLOSED',
};

export const FaqQuestion = {
  TYPE_MEMBER: 'member',
  TYPE_COUNTRY: 'country',
};

export const FaqAnswer = {
  TYPE_RICH_TEXT: 'RICH_TEXT',
  TYPE_MEDIA: 'MEDIA',
};

export const CourseChapter = {
  TYPE_SECTION: 'SECTION',
  TYPE_LESSON: 'LESSON',
  TYPE_QUIZ: 'QUIZ',
  TYPE_EVALUATION: 'EVALUATION',
};

export const CourseSession = {
  STATUS_IN_PROGRESS: 'IN_PROGRESS',
  STATUS_DONE: 'DONE',
  STATUS_FAILED: 'FAILED',
  MAX_EVALUATION_ATTEMPTS: 2,
};

export const CourseStepBlock = {
  TYPE_RICH_TEXT: 'RICH_TEXT',
  TYPE_MEDIA: 'MEDIA',
  TYPE_H5P: 'H5P',
};

export const Publication = {
  STATE_DRAFT: 'state.draft',
  STATE_PUBLISHED: 'state.published',
  PUBLISHED_STATUS_ALL: 'all',
};

export const Resource = {
  LABELIZATION_STATUS_NONE: 'none',
  LABELIZATION_STATUS_PENDING: 'pending',
  LABELIZATION_STATUS_REFUSED: 'refused',
  LABELIZATION_STATUS_ACCEPTED: 'accepted',
};

export const Webinar = {
  STATUS_IN_COMING: 'status.in_coming',
  STATUS_TEST_IN_PROGRESS: 'status.test_in_progress',
  STATUS_TEST_FINISHED: 'status.test_finished',
  STATUS_IN_PROGRESS: 'status.in_progress',
  STATUS_FINISHED: 'status.finished',
  STATUS_UNDONE: 'status.undone',
};

export const Expertise = {
  STATUS_NONE: 'none',
  STATUS_PENDING: 'pending',
  STATUS_APPROVED: 'approved',
  STATUS_REJECTED: 'rejected',
  TRAINING_TYPE_ONLINE: 'online',
  TRAINING_TYPE_IN_PERSON: 'in_person',
  TRAINING_TYPE_BOTH: 'both',
  AFFILIATION_INDEPENDENT: 'independent',
  AFFILIATION_EMPLOYEE: 'employee',
};
